import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const homeHeaderCarousels = document.querySelectorAll(
    '.home-header__carrousel'
  );

  if (0 < homeHeaderCarousels.length) {
    const coutCurrentSlide = document.querySelector('.current-slide');
    const countTotalSlides = document.querySelector('.total-slides');
    homeHeaderCarousels.forEach(function (homeHeaderCarousel) {
      const swiperConfig = {
        slidesPerView: 1,
        pagination: {
          el: '.home-header_swiper-pagination',
          clickable: true,
        },
        on: {
          slideChange(swiper) {
            coutCurrentSlide.textContent = swiper.realIndex + 1;
          },
        },
      };

      const swiper = new Swiper(
        homeHeaderCarousel.querySelector('.home-header-swiper'),
        swiperConfig
      );

      // Set the total number of slides
      countTotalSlides.textContent = swiper.slides.length;
    });
  }
});
